import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import "./css/nav.css";

export default function Navbar() {
  const [switchToggled, setSwitchToggled] = useState(true);
  const [activeMenu, setActiveMenu] = useState("home");
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split('/')[1];
    setActiveMenu(path || 'home');
  }, [location]);

  const toggleSwitch = () => {
    setSwitchToggled(!switchToggled);
  };

  return (
    <div>
      <section className="part-1">
        <div className="home">
          <div className="top-bar">
            <ul>
              <li><a href="mailto:sirajcottonmill@gmail.com"><i className="fa-solid fa-envelope"></i>sirajcottonmill@gmail.com</a></li>
              <li><a href={'//www.facebook.com/profile.php?id=100080408127162'} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook"></i></a></li>
              <li><a href={'//instagram.com/siraj_cotton_mills?igshid=YmMyMTA2M2Y='} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram"></i></a></li>
            </ul>
          </div>
          <nav className="navbar_container">
            <img src="assets/images/logo.png" alt="logo" className="logo" />
            <ul className={switchToggled ? "menu" : "menu show"}>
              <li><span className={activeMenu === "home" ? "active" : ""} onClick={toggleSwitch}><Link to='/'>Home</Link></span></li>
              <li>
                <a>Our Products <i className="fa-solid fa-caret-down"></i></a>
                <ul className="drp-down">
                  <li onClick={toggleSwitch}><Link to='/frenchterry' data-after="French-terry">French Terry</Link></li>
                  <li onClick={toggleSwitch}><Link to='/jerseyfabric' data-after="Jersey-Fabric">Jersey Fabric</Link></li>
                  <li onClick={toggleSwitch}><Link to='/pique' data-after="pique">Pique</Link></li>
                  <li onClick={toggleSwitch}><Link to='/fleece' data-after="fleece">Fleece</Link></li>
                  <li onClick={toggleSwitch}><Link to='/lycrafine' data-after="lycra-fine">Lycra Fine</Link></li>
                  <li onClick={toggleSwitch}><Link to='/lycrarib' data-after="lycra-rib">Lycra Derby Rib</Link></li>
                  <li onClick={toggleSwitch}><Link to='/designfab' data-after="design-fab">Design Fabrics</Link></li>
                </ul>
              </li>
              <li><span className={activeMenu === "acheivement" ? "active" : ""} onClick={toggleSwitch}><Link to='/acheivement'>Achievements</Link></span></li>
              <li><span className={activeMenu === "aboutus" ? "active" : ""} onClick={toggleSwitch}><Link to='/about' data-after="About Us">About Us</Link></span></li>
              <li><span className={activeMenu === "gallery" ? "active" : ""} onClick={toggleSwitch}><Link to='/gallery' data-after="gallery">Gallery</Link></span></li>
              <li><span className={activeMenu === "contact" ? "active" : ""} onClick={toggleSwitch}><Link to='/contact' data-after="contact-2" className="contact-2">Contact</Link></span></li>
            </ul>
            <div className="nav_third_part">
              <div className="call_icon_container">
                <img src="assets/images/nav_call.png" className="call_icon" alt="call icon" />
              </div>
              <div className="call_text_container">
                <p>For Enquiry</p>
                <h4><a href="tel:9994927886">9994927886</a></h4>
              </div>
            </div>
            <div className="hamburger" id="hamburger" onClick={toggleSwitch}>
              <i className={switchToggled ? "fas fa-bars" : "fas fa-times"} id="header-toggle"></i>
            </div>
          </nav>
        </div>
      </section>
    </div>
  );
}
