
import React from "react";
import ReactDOM from 'react-dom';
import"./css/Footer.css";
import {Link} from 'react-router-dom';

class Foot extends React.Component{
    render()
    {
        return(
<>
<section className="foot">
  <footer className="footer-distributed">

    <div className="footer-left">
        <img src="assets/images/logo-white.png"/>
      

      <p className="footer-links">
      <Link to='/' data-after="Home">Home</Link>
        |
        <Link to='/frenchterry'  data-after="French-terry">Products</Link>
        |
        <Link to='/acheivement'>Achievements</Link>
        |
        <Link to='/about'  data-after="About Us">About Us</Link>
        |
        <Link to='/gallery' data-after="gallery">Gallery</Link>
        |
        <Link to ='/contact'  data-after="contact-2" class="contact-2">Contact</Link>
      </p>

      <p className="footer-company-name">© 2022 siraj cottonmills</p>
      <p className="footer-company-name">Design and Developed by <a href="https://clicktogain.tech/" className="developer_name">Click To Gain</a></p>
    </div>

    <div className="footer-center">
      <div>
        <i className="fa fa-map-marker"></i>
          <p><span>154-B, Bigbazar Street,</span>
            Tirupur, Tamil Nadu- 641604</p>
      </div>

      <div>
        <i className="fa fa-phone"></i>
        <p>9994927886</p>
      </div>
      <div>
        <i className="fa fa-envelope"></i>
        <p><a href="mailto:sirajcottonmill@gmail.com">sirajcottonmill@gmail.com</a></p>
      </div>
    </div>
    <div className="footer-right">
      <p className="footer-company-about">
        <span>About the company</span>
        Our Company Is A Reputed Manufacturer & Supplier Of Products Such As OE Yarns And OE Fabrics and Avail Ordered Products At Reasonable Price In The Market. </p>
      <div className="footer-icons">
        <a href={'//www.facebook.com/profile.php?id=100080408127162'}  target="_blank" rel="noreferrer noopener"><i className="fa fa-facebook"></i></a>
        <a href="#"><i className="fa fa-twitter"></i></a>
        <a href={'//instagram.com/siraj_cotton_mills?igshid=YmMyMTA2M2Y='}><i className="fa fa-instagram"></i></a>
        <a href="#"><i className="fa fa-youtube"></i></a>
      </div>
    </div>
  </footer>
</section>

</>
 );
}
}

export default Foot;