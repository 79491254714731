import React from 'react';
import { render } from 'react-dom';

import "./gallery.css";
import LightGallery from 'lightgallery/react';
import Slide from 'react-reveal/Slide';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';


export default function Photo() {
    return (
        <>


            <div className='gallery-back'>
                <br/>
            <div className='story'>
      <Slide top cascade><span className="subhead_small"><img src="assets/images/sub_img.png" />Our Gallery</span>
  <h1>
  Fabric Gallery: A Kaleidoscope of Textile Delights
  </h1>
</Slide>
</div>
<br/>

                <LightGallery
                    speed={500}
                    plugins={[lgThumbnail, lgZoom]}
                    elementClassNames="gallery-body"
                    animateThumb={true}
                >

                    <a href="assets/images/siraj-fabric2.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Design Fabrics</h4>"><img src="assets/images/siraj-fabric2.jpg" alt="oe fabric siraj cotton mills" srcset="" /></a>
                    <a href="assets/images/fabric-2.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Jersey Fabrics</h4>"><img src="assets/images/fabric-2.jpg" alt="single jersey fabrics" srcset="" /></a>
                    <a href="assets/images/sinker_stripes.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Sinker Stripes</h4>"><img src="assets/images/sinker_stripes.jpg" alt="siraj textiles" srcset="" /></a>

                    <a href="assets/images/4way.jpg" data-download-url={false} className="medium" data-sub-html="<h4>4way (Super swed)</h4>"><img src="assets/images/4way.jpg" alt="siraj cotton mills" srcset="" /></a>
                    <a href="assets/images/siraj-fabric3.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Design Fabrics</h4>"><img src="assets/images/siraj-fabric3.jpg" alt="siraj textiles" srcset="" /></a>
                  
               
                    <a href="assets/images/siraj-fabric5.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Polyster Fabrics</h4>"><img src="assets/images/siraj-fabric5.jpg" alt="siraj textiles polyester fabric" srcset="" /></a>
                    <a href="assets/images/military_print.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Military Print</h4>"><img src="assets/images/military_print.jpg" alt="siraj cotton mills" srcset="" /></a>
                    <a href="assets/images/siraj-showroom.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Fabric Display Section</h4>" ><img src="assets/images/siraj-showroom.jpg" alt="" srcset="" /></a>
                    <a href="assets/images/siraj-fabric6.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Dot Design</h4>"><img src="assets/images/siraj-fabric6.jpg" alt="siraj-fabric" srcset="" /></a>
                    <a href="assets/images/siraj-fabric7.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Solid Fabrics</h4>"><img src="assets/images/siraj-fabric7.jpg" alt="siraj textiles oe fabric" srcset="" /></a>
                 
                    <a href="assets/images/Deep.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Deep</h4>"><img src="assets/images/Deep.jpg" alt="siraj textiles fabric" srcset="" /></a>
                    <a href="assets/images/Bricks.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Bricks</h4>"><img src="assets/images/Bricks.jpg" alt="siraj textiles fabric" srcset="" /></a>
                    <a href="assets/images/siraj-fabric9.jpg" data-download-url={false} className="medium" data-sub-html="<h4>French Terry fabrics</h4>"><img src="assets/images/siraj-fabric9.jpg" alt="siraj textiles french terry" srcset="" /></a>
                  
                    <a href="assets/images/King_roma.jpg" data-download-url={false} className="medium" data-sub-html="<h4>King roma</h4>"><img src="assets/images/King_roma.jpg" alt="siraj textiles " srcset="" /></a>
                    <a href="assets/images/Dot_knit.jpg" data-download-url={false} className="medium" data-sub-html="<h4>King roma</h4>"><img src="assets/images/Dot_knit.jpg" alt="siraj textiles " srcset="" /></a>
                    <a href="assets/images/Popcorn.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Popcorn</h4>"><img src="assets/images/Popcorn.jpg" alt="siraj textiles " srcset="" /></a>
                    <a href="assets/images/Rice_knit.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Rice Knit</h4>"><img src="assets/images/Rice_knit.jpg" alt="siraj textiles " srcset="" /></a>
                    <a href="assets/images/twill.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Twill</h4>"><img src="assets/images/twill.jpg" alt="siraj textiles " srcset="" /></a>
                    <a href="assets/images/Karara.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Karara</h4>"><img src="assets/images/Karara.jpg" alt="siraj textiles " srcset="" /></a>
                    <a href="assets/images/Snowfall.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Snowfall</h4>"><img src="assets/images/Snowfall.jpg" alt="siraj textiles " srcset="" /></a>
                    <a href="assets/images/polomatty.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Polo Matty</h4>"><img src="assets/images/polomatty.jpg" alt="siraj textiles " srcset="" /></a>
                    <a href="assets/images/Snowfall_print.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Snowfall print</h4>"><img src="assets/images/Snowfall_print.jpg" alt="siraj textiles " srcset="" /></a>
                    <a href="assets/images/siraj-fabric10.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Special Colours</h4>"><img src="assets/images/siraj-fabric10.jpg" alt="siraj textiles " srcset="" /></a>
                    <a href="assets/images/Valentino.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Valentino</h4>"><img src="assets/images/Valentino.jpg" alt="siraj textiles " srcset="" /></a>
                    <a href="assets/images/siraj-fabric8.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Special Colours</h4>"><img src="assets/images/siraj-fabric8.jpg" alt="siraj textiles fabric" srcset="" /></a>
                    <a href="assets/images/Berlin.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Berlin</h4>"><img src="assets/images/Berlin.jpg" alt="siraj textiles " srcset="" /></a>
                    <a href="assets/images/siraj-fabric4.jpg" data-download-url={false} className="medium" data-sub-html="<h4>Design Fabrics</h4>"><img src="assets/images/siraj-fabric4.jpg" alt="siraj textiles design fabric" srcset="" /></a>
                    <a href="assets/images/4way_Lycra_messy.jpg" data-download-url={false} className="medium" data-sub-html="<h4>4way Lycra messy</h4>"><img src="assets/images/4way_Lycra_messy.jpg" alt="siraj textiles " srcset="" /></a>
                </LightGallery>

            </div>

        </>
    );
}
