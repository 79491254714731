import React from "react";
import "./css/product.css";
import {Link} from 'react-router-dom';
export default function JerseyFabric()
{
 
  return(
    
    <>
    <section className="fabric-body">
    <img src="assets/images/asset_fabric_two.png" className='asset_fabric_two'/>
    <img src="assets/images/asset_fabric.png" className='asset_fabric'/>
  <div className="pro-head"> <h1 id="Jersey Fabrics-title">Jersey Fabrics</h1>
  <span className="pro-line"></span>
  </div>
  <div className="fab-container">
      <div className="fab-card">
          <div className="fab-picBackground">
              <div className="gradients">
                  
                  <div className="gradient second" color="red"></div>
                  <div className="gradient" color="green"></div>
                  <div className="gradient" color="orange"></div>
                  <div className="gradient" color="black"></div>
              </div>
              <h1 className="sira-transprent">siraj</h1>
              <img src="assets/images/logo-white.png" alt="" className="logo-product"/>
              <a href="#" className="share"><i className="fas fa-share-alt"></i></a>
              <div className="image-cont">
              <img src="assets/images/siraj-jersey-fabric.jpg" alt="siraj-jersey-fabric" className="fab show" color="blue"/>
              <img src="img/red.png" alt="" className="fab" color="red"/>
              <img src="img/green.png" alt="" className="fab" color="green"/>
              <img src="img/orange.png" alt="" className="fab" color="orange"/>
              <img src="img/black.png" alt="" className="fab" color="black"/>
              </div>
          </div>
          <div className="info">
              <div className="fabName">
                  <div>
                      <h1 className="big">Jersey Fabrics</h1>
                     
                  </div>
                  
              </div>
              <div className="description">
                  <h3 className="title">Product Info</h3>
                  <p className="text">Experience the epitome of comfort and style with our single jersey fabric. Designed for versatility and ease, this fabric offers a lightweight and breathable solution for a wide range of apparel creations. From casual tees to everyday basics, our single jersey fabric provides the perfect combination of comfort and style. With its smooth texture and impeccable drape, it's the go-to choice for designers looking to create effortlessly chic garments for their collections.</p>
              </div>
              <div className="color-container">
                  <h3 className="title">Colours</h3>
                  <div className="colors">
                      <span className="color active" primary="#2175f5" color="blue"></span>
                      <span className="color" primary="#f84848" color="red"></span>
                      <span className="color" primary="#29b864" color="green"></span>
                      <span className="color" primary="#ff5521" color="orange"></span>
                      <span className="color" primary="#444" color="black"></span>
                      <span className="color" primary="#444" color="spl-clr-1"></span>
                      <span className="color" primary="#444" color="spl-clr-2"></span>
                      <span className="color" primary="#444" color="spl-clr-3"></span>
                      <span className="color" primary="#444" color="spl-clr-4"></span>
                  </div>
              </div>
              <div className="dia-container">
                  <h3 className="title">dia</h3>
                  <div className="dias">
                      <h4>any dia on order based</h4>
                  </div>
              </div>
              <div className="gsm-container">
                  <h3 className="title">Gsm</h3>
                  <div className="gsm">
                      <h4>160-200</h4>
                  </div>
              </div>
              <div className="cont-price">
                  <Link to='/contact' className="cont"><i className="fas fa-shopping-cart"></i>Book Your free sample</Link>
                  
              </div>
          </div>
      </div>
  </div>
</section>
    </> 
        );
     
    }