import React from "react";
import "./css/blog.css";
import {Link} from 'react-router-dom';
import Slide from 'react-reveal/Slide';
export default function Blog2()
{
 
  return(
    
    <>
    <div className="blog-body">
    <header>
    <div className="story" >

<Slide top cascade><span className="subhead_small"><img src="assets/images/sub_img.png" />Our Blog</span>
  <h1>
  The Fabric Chronicles: Exploring Trends, Tips,<br/> and Inspiration - Our Blog
  </h1>
</Slide>
</div>
 </header>
 <div className="blg-row">
 	<div className="left-column">
 	  <div className="blg-card">
 		<h2>Cotton Recycling</h2>
 		<h5>Cotton, Apr 26, 2022</h5>
 		<img src="assets/images/blog-2 big.jpg" alt="siraj-blog "/>
 		<p>
         <b>Cotton Recycling Benefits:</b>
         <br/>
<li>It can be used as insulation, rags, or stuffing in areas where it regenerates.</li>

<li>Recycling eliminates unnecessary waste and diverts a variety of items from landfills. The Council for Textile Recycling estimates that around 25 billion pounds of textile waste are generated annually.</li>

<li>Because it is already colored, additional colouring is not necessary upon recycling.</li>

<li>Energy, water, and dyestuff use are minimised since recycled cotton yarns are often supplied from colour-sorted pre-consumer textiles.</li>

<li>Reduce CO2 and other fossil fuel emissions.</li>
</p>
<p>
<b>Cotton Recycling’s Difficulties:</b>
<br/>
Cotton grows weaker during recycling and must therefore be combined with other fibres to create a fresh yarn with the appropriate strength and durability; it cannot be recycled further.
Any amount of recycled material will affect the yarn and fabric’s length, strength, evenness, and uniformity.
Costs are usually greater than those associated with conventional cotton processing.
The test findings are not particularly encouraging.
A maximum of 30% recycled cotton may be utilised in a product.
Consumer interest in purchasing recycled cotton products at a higher price point is decreasing.
Additionally, recycled cotton must be blended with other fibres, such as (recycled) polyester, to produce a high-quality end product. This is why using current technologies, one will not find pieces made entirely of recycled cotton. While some producers strive for a minimum of 50% recycled cotton content, recycled cotton is still preferable to freshly made textiles if the waste reduction is a primary issue.
</p>
<p>
<b>Conclusion</b>
<br/>
In terms of sustainability, recyclable cotton is a hot issue that manufacturers, brands, and retailers are all interested in. For the whole textile supply chain, recycled cotton can be critical to achieving the objective of sustainability. Cotton is the most popular fibre, accounting for around 56% of total fibre consumption worldwide. It delivers consistent high-quality performance since it is adaptable, breathable, and extremely pleasant to wear.
Cotton that has been recycled avoids needless waste and may be a more sustainable option than disposal. It can be derived from recycled garments or from textile waste or leftovers that are subsequently spun into new yarns and fabric. Additionally, it is sometimes referred to as regenerated or recycled cotton.

         </p>
 	  </div>
 	 
 	</div>

 	<div className="right-column">
 		<div className="blg-card">
 			 <h3>Popular Post</h3>
              <Link to='/blog1' ><img src="assets/images/blog-1.jpg" alt="siraj-blog"/></Link>
 		<Link to='/blog3'>	 <img src="assets/images/oe yarn mini.jpg" alt="siraj-blog"/></Link>
 			
 		</div>
 		<div className="blg-card">
 			<h1>Follow Us</h1>
             <span id="line-2" ></span>
 			<div className="social-media blg-media">
              <div className="social-icons">
                <a href="#">
                  <i className="fa fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fa fa-instagram"></i>
                </a>
                <a href="#">
                  <i className="fa fa-youtube"></i>
                </a>
              </div>
            </div>
 		</div>
 	</div>
 </div>


</div>
    </>
  );
}