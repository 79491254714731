import react from 'react';
import Navbar from './pages/nav.js';
import Home from './pages/home.js';
import FrenchTerry from './pages/French-terry';
import JerseyFabric from './pages/Jersey-fabric.js';
import Pique from './pages/Pique';
import Lycrafine from './pages/Lycra-fine';
import Lycrarib from './pages/Lycra-rib';
import DesignFabric from './pages/Design-fabric.js';
import About from './pages/About-us';
import Contact from './pages/Contact';
import Fleece from './pages/Fleece'
import Footer from './pages/Footer';
import { HashRouter, Router, Routes , Route,  Link } from 'react-router-dom';
import Photo from './pages/gallery';
import Achievements from './pages/achievements.js';
import Infrastructure from './pages/infrastructure.js';
import Blog1 from './pages/Blog-1.js';
import Blog2 from './pages/Blog-2.js';
import Blog3 from './pages/Blog-3.js';
import ScrollToTop from "./pages/ScrollToTop.js";


function App() {
 
   

  
  return (
<>  
<HashRouter
getUserConfirmation={(message, callback) => {
  // this is the default behavior
  const allowTransition = window.confirm(message);
  callback(allowTransition);
}}
basename="/"
>

<Navbar />
<ScrollToTop>
<Routes>
<Route path='/' exact element={<Home/>} />
<Route path='/frenchterry' exact element={<FrenchTerry/>} />
<Route path='/jerseyfabric' exact element={<JerseyFabric/>} />
<Route path='/pique' exact element={<Pique/>} />
<Route path='/fleece' exact element={<Fleece/>} />
<Route path='/lycrafine' exact element={<Lycrafine/>} />
<Route path='/lycrarib' exact element={<Lycrarib/>} />
<Route path='/designfab' exact element={<DesignFabric/>} />
<Route path='/gallery' exact element={<Photo/> }/>
<Route path='/acheivement' exact element={<Achievements/>}/>
<Route path='/about' exact element={<About/>} />
<Route path='/photoalbum'  exact element={<Photo/>} />
<Route path='/contact' exact element={<Contact/>} />
<Route path='/infrastucture' exact element={<Infrastructure/>} />
<Route path='/blog1' exact element={<Blog1/>}/>
<Route path='/blog2' exact element={<Blog2/>}/>
<Route path='/blog3' exact element={<Blog3/>}/>
</Routes>
</ScrollToTop>
<Footer/>

</HashRouter>
</>




  );
}

export default App;
