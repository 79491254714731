import React from "react";
import "./css/contact.css";
import emailjs from 'emailjs-com';


  function sendEmail(e){
    e.preventDefault();

    emailjs.sendForm('service_l0s324b', 'template_8rdo0eq', e.target,'6Fqk58w9fHoZuGN7f').then(res=>{
      console.log(res);
      alert("Thankyou For Contacting Us!");
    }).catch(err=> console.log(err));
  }



export default function Contact()
{
 
  return(
    <>
    <div className="contact-box">
        <span className="big-circle"></span>
        <img src="assets/images/shape.png" className="square" alt="" />
        <div className="form">
          <div className="contact-info">
            <h3 className="cont-head">Let's get in touch</h3>
            <p className="cont-txt">
              If you have any comments or queires please feel free to contact us
            </p>
  
            <div className="info">
              <div className="information">
                <img src="assets/images/location.png" className="add-img" alt="" />
                <p> Bigbazzar St, Noyyal, Tiruppur, Tamil Nadu 641604</p>
              </div>
              <div className="information">
                <img src="assets/images/email.png" className="add-img" alt="" />
                <p className="mail">sirajcottonmill@gmail.com</p>
              </div>
              <div className="information">
                <img src="assets/images/phone.png" className="add-img" alt="" />
                <p>9994927886</p>
              </div>
            </div>
  
            <div className="social-media">
              <p>Connect with us :</p>
              <div className="social-icons">
                <a href={'//www.facebook.com/profile.php?id=100080408127162'}>
                  <i className="fa fa-facebook-f"></i>
                </a>
                <a href={'//instagram.com/siraj_cotton_mills?igshid=YmMyMTA2M2Y='}>
                  <i className="fa fa-instagram"></i>
                </a>
                <a href="https://www.youtube.com/@sirajcottonmills4457">
                  <i className="fa fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
  
          <div className="contact-form">
            <span className="circle one"></span>
            <span className="circle two"></span>
  
            <form  autocomplete="on" onSubmit={sendEmail}>
              <h3 className="cont-head">Contact us</h3>
              <div className="input-container">
                <input type="text" name="name" className="field" id="name" placeholder="Name" required/>
               
                
              </div>
              <div className="input-container">
                <input type="email" name="user-email" className="field" id="user-email" placeholder="E-mail" required/>
                
                
              </div>
              <div className="input-container">
                <input type="text" name="company" className="field" id="company" placeholder="Company" />
                
                
              </div>
              <div className="input-container">
                <input type="tel" name="phone" className="field" id="tel" placeholder="Phone" required/>
                
               
              </div>
              <div className="input-container textarea">
                <textarea name="message" className="field" id="message" placeholder="Message"></textarea>
             
              </div>
              <input type="submit" value="Send" className="btn-contact" />
            </form>
          </div>
        </div>
      </div>
      <div className="map-Container">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3915.153244000204!2d77.35257001428926!3d11.101954456137557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba907bf5aa4c5ab%3A0x49b0b1fb6b180444!2sSiraj%20Textiles%20and%20cotton%20mills!5e0!3m2!1sen!2sin!4v1649582392211!5m2!1sen!2sin" width="600" height="300"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

      </div>
  
    </>
  );}