import React from "react";
import "./css/product.css";
import {Link} from 'react-router-dom';
export default function Fleece()
{
 
  return(
    
    <>
    
<section className="fabric-body">
<img src="assets/images/asset_fabric_two.png" className='asset_fabric_two'/>
    <img src="assets/images/asset_fabric.png" className='asset_fabric'/>
  <div className="pro-head"> <h1 id="Fleece-title">Fleece</h1>
  <span className="pro-line"></span>
  </div>
  <div className="fab-container">
      <div className="fab-card">
          <div className="fab-picBackground">
              <div className="gradients">
                  
                  <div className="gradient " color="red"></div>
                  <div className="gradient" color="green"></div>
                  <div className="gradient second" color="orange"></div>
                  <div className="gradient" color="black"></div>
              </div>
              <h1 className="sira-transprent">siraj</h1>
              <img src="assets/images/logo-white.png" alt="" className="logo-product"/>
              <a href="#" className="share"><i className="fas fa-share-alt"></i></a>
              <div className="image-cont">
              <img src="assets/images/siraj-fleece-fabric.jpg" alt="siraj-fleece" className="fab show" color="blue"/>
              <img src="img/red.png" alt="" className="fab" color="red"/>
              <img src="img/green.png" alt="" className="fab" color="green"/>
              <img src="img/orange.png" alt="" className="fab" color="orange"/>
              <img src="img/black.png" alt="" className="fab" color="black"/>
              </div>
          </div>
          <div className="info">
              <div className="fabName">
                  <div>
                      <h1 className="big">Fleece</h1>
                     
                  </div>
                  
              </div>
              <div className="description">
                  <h3 className="title">Product Info</h3>
                  <p className="text">Embrace warmth and comfort with our exquisite fleece fabric, meticulously crafted to elevate your creations with unparalleled coziness. Designed for chilly days and cozy nights, our fleece fabric envelops you in luxurious softness while providing exceptional insulation against the cold. Perfect for crafting stylish outerwear, cozy blankets, or comfortable loungewear, its plush texture and insulating properties make it a must-have for any designer's collection. Experience the ultimate in comfort and style with our premium fleece fabric, where luxury meets functionality for all your apparel and home decor needs.</p>
              </div>
              <div className="color-container">
                  <h3 className="title">Colours</h3>
                  <div className="colors">
                      <span className="color active" primary="#2175f5" color="blue"></span>
                      <span className="color" primary="#f84848" color="red"></span>
                      <span className="color" primary="#29b864" color="green"></span>
                      <span className="color" primary="#ff5521" color="orange"></span>
                      <span className="color" primary="#444" color="black"></span>
                      <span className="color" primary="#444" color="spl-clr-1"></span>
                      <span className="color" primary="#444" color="spl-clr-2"></span>
                      <span className="color" primary="#444" color="spl-clr-3"></span>
                      <span className="color" primary="#444" color="spl-clr-4"></span>
                  </div>
              </div>
              <div className="dia-container">
                  <h3 className="title">dia</h3>
                  <div className="dias">
                      <h4>any dia on order based</h4>
                  </div>
              </div>
              <div className="gsm-container">
                  <h3 className="title">Gsm</h3>
                  <div className="gsm">
                      <h4>160-200</h4>
                  </div>
              </div>
              <div className="cont-price">
                  <Link to='/contact'  className="cont"><i className="fas fa-shopping-cart"></i>Book Your free sample</Link>
                  
              </div>
          </div>
      </div>
  </div>
</section>
    </>
      
    );
     
   }