import React from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, EffectFlip, Pagination, Autoplay } from "swiper";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import Slide from 'react-reveal/Slide';
import "./css/about.css";
import Roll from 'react-reveal/Roll';
export default function About()
{
 
  return(
    
    <div className="about_all_container">
     <section className="abt-part-1">
<div className="container-ban">
        <div className="about-banner">
            <img src="assets/images/siraj-abt-banner.jpg" alt="siraj cotton mills" srcset=""/>
            
        </div>
        <div className="ban-content">
            <h1>About US</h1>
        </div>
    </div>
    <div className='story'>
      <Slide top cascade><span className="subhead_small"><img src="assets/images/sub_img.png" />About Us</span>
  <h1>
  Our Story: Inspiring Creativity and Excellence
  </h1>
</Slide>
</div>
<br/>
<div className="about-text-container">

    <div className="swiper-2">
    <div className="swiper-container-2">
      <Swiper
      modules={[Navigation,Pagination, Autoplay]}
      navigation={false}
      pagination={{clickable:true}}
      effect
      autoplay={{
        
    disableOnInteraction: false
  } }
      speed={3000}
      slidesPerView={1}
      loop={true}
      className="swiper-wrapper"
      >


    <SwiperSlide className="swiper-slide">
    <div className="image__wrapper-2">
                        <img src="assets/images/office.jpg" alt="siraj-office" />
                    </div>
                    
                  
    </SwiperSlide>
    <SwiperSlide className="swiper-slide">
                    <div className="image__wrapper-2">
                        <img src="assets/images/abt-slide-2.jpg" alt="siraj-knitting" />
                    </div>
                   
    </SwiperSlide>
    <SwiperSlide className="swiper-slide">
    <div className="image__wrapper-2">
                        <img src="assets/images/abt-slide-3.jpg" alt="siraj-spinnig mills" />
                    </div>
                    
    </SwiperSlide>

      </Swiper>
    </div>
    </div>
    <span>
    <p> "The Siraj Group of Companies" Was Formed in 1955. In the Initial Stage, the Founder "Mr.k.p.kamaluddin” Initiated This Company by Dealing With "Cotton Rags" and "Cotton Wipers”. The Business Was Focused on Meeting the Demand of the Textile Manufacturers at a Wide Range. Later, This Business Was Directed by "Mr.k Muhammad Ishak".he Widened the Business Operation Worldwide and Also With the Grace of the Almighty Became a Well-known Supplier in the Market.
</p>
<br/>
<p>After That, His Son " Mr.Mohammad Bilal", Took This Business to the Next Stage With the Guidance of His Father by Starting the Spinning Mill in 2007  and Started Deal With Recycled Cotton and Oe Yarns. He Started to Export Cotton  Worldwide During That Period. Later in 2008, Siraj Group of Company Entered the Fabric Market and Start Exporting Oe Fabrics.</p>

<img src="assets/images/asset_grow.png" alt="asset_grow" className="asset_grow"/>
<br/>

<p>
Now Siraj Is One of the Leading Manufacturers and Exporters of Oe Fabrics, Cotton Waste, and Cutting Waste. The Oe Fabrics Include Single Jersey, Loop Knit, Rib Fabric, Etc.., This Entity Aims to Achieve Client Requirements With Their Satisfaction by Producing Quality Fabrics at the Reasonable Price. 
We Also Aim to meet the Market Demand by Knowing the Market Pulse as Per the Season and the Client's requirements. We Deliver a Product at a High Range Organization, Which Is Dedicated to Offering High-grade Fabrics. It Is Concerned That the Raw Material Is Bought From Authorized Vendors. All the Fabrics That Are Supplied to the Clients Are Checked That They Made the Parameters Mentioned by the Client.
We Manufacture the Fabric Which Is Well Known for Its Smooth Structure and Business Range Is Long-lasting the Fabric Can Be Roughly Used and Does Not Fade Even After Several Washes Which Makes Us a Trusted Supplier. Our Entity Is Capable of Enough to Take the Bulk Order Because of Highly Equipped Infrastructure Support and Well Packed and Deliver on Time.
We Provide You the Guarantee With Quality Fabrics We Have the Qualified and Experienced Professionals for the Process of Product Formation Our Team Works on the Requirement of the Market and Client's Need, We Continue in Developing the Grade at the Most Level to Get Well Known Worldwide.

</p>
<br/>
<p>
"Siraj Group of Company" Has 67 Years of Experience in the Field It Is One of the Leading and Experienced Fabric Manufacturers in the Market. We Aim to Satisfy the Customer's Desires for Their Need by Providing Various Quality as Per the Client's Economic Level. Friendly Staff Which Helps Clients to Proceed With Their Order Smoothly and Quickly. Separate Every Order So the Client Can Access Details Conveniently. This Is Well Known for Its Quality of Output.
</p>
</span>
    
    </div>
    
    </section>
    <br/>
    <section className="abt-part-2">
        <div className="container-back">
            <div className="box-2">
                <i className="fa-solid fa-quote-left fa2"></i>
                <div className="text"><i className="fa-solid fa-quote-right fa1"></i>
                    <div className="txt-cont">
                        <h3>Quality policy</h3>
                        <span id="line-2"></span>
                        <p>Siraj group of company is committed to providing mutually accepted quality fabrics to ensure customer satisfaction by meeting their specified requirements in time, at a competitive price, through the dedication and teamwork of its employees and continual improvement  in the quality management system</p>
                    </div>
                </div>
            </div>
        </div>
        <br/><br/>
    </section>
    <section className="abt-part-3">
        <div className="strengths">
        <div className='story'>
      <Slide top cascade><span className="subhead_small"><img src="assets/images/sub_img.png" />Our Strengths</span>
  <h1>
  Empowering Success through<br/> Distinctive Capabilities
  </h1>
</Slide>
</div>
            <img src="assets/images/strenght.jpg" alt="siraj-strenght" className="our_strenght"/>
            <Slide bottom>
            <p>
            At Siraj Group Of Companies, we truly believe that the strength of our company lies in our talented and dedicated workforce. We strive hard to keep our foundation happy and strong which in turn helps us achieve all our goals without limitations. Integrity, honesty, trust, mutual respect, and offering equal opportunities for all concerned in our business life and practice are all ingrained within the Siraj Group of companies fraternity.
            </p>
            </Slide>
        </div>
    </section>
    <br/><br/><br/>
  {/* <section className="customer-section">


        <div className="row">
      
            <div className="cus-image">
            <Roll left>
                <img src="assets/images/customer-satisfaction-feature.png" alt="siraj-cotton mills"/>
                </Roll>
            </div>
      
            <div className="content">
              <h1>Customer Satisfaction</h1>
              <span id="line-3"></span>
              <Roll right>
              <p>
              Our company focused on client satisfaction and needs. We strive hard to meet client satisfaction. Our organization is client-centric, which modulates the product according to the client. We value the time and money of our clients and give them the best quality unmatchable product. We are backed by a sophisticated production unit and also take the bulk order of fabrics on the demand of the client. We make sure that the order will be delivered on time.</p>
              </Roll>
              
            </div>
      
        </div>
      
      </section>
*/}
<section className="fabricProcessSection">
<div className='story'>
      <Slide top cascade><span className="subhead_small"><img src="assets/images/sub_img.png" />Our Processing</span>
  <h1>
  Processing Excellence:<br/> Nurturing Fabrics to Perfection
  </h1>
</Slide>
</div>
<br/>
  <div className="fabricProcess">
<div className="processOne">
  <div className="process_img_container_one">
<img src="assets/images/knitting.jpg" className="knitting_procss_img"/>
  </div>
  <div className="process_text_container_one">
  <div className="process_text_container_inner">
    <div className="process_head">
     <h1> 1.Knitting</h1>
    </div>
    <div className="process_content">
    Witness the artistry of the knitting process in OE fabric production, where skilled hands weave threads into intricate patterns, creating fabrics that exude elegance and comfort. Experience the seamless blend of craftsmanship and technology as every stitch comes together, resulting in exquisite textiles ready to be transformed into fashionable masterpieces.
    </div>
    </div>
  </div>
</div>
<div className="processTwo">
  
  <div className="process_text_container_two">
    <div className="process_text_container_inner">
    <div className="process_head">
     <h1> 2.Washing</h1>
    </div>
    <div className="process_content">
    Immerse yourself in the meticulous washing process of OE fabric, where attention to detail ensures optimal softness and durability. Through careful treatment, our fabrics undergo a transformative journey, emerging beautifully cleansed and ready to embrace your everyday moments with unmatched comfort.
    </div>
    </div>
  </div>
  <div className="process_img_container_two">
<img src="assets/images/washing_section.jpg" className="washing_section_img"/>
  </div>
</div>
<div className="processOne">
  <div className="process_img_container_one">
<img src="assets/images/compacting.png" className="compacting_procss_img"/>
  </div>
  <div className="process_text_container_one">
  <div className="process_text_container_inner">
    <div className="process_head">
     <h1> 3.Compacting</h1>
    </div>
    <div className="process_content">
    Experience the transformative power of the compacting process in OE fabric production, where fabrics are gently compressed to enhance their density and stability. Witness the meticulous craftsmanship as our fabrics undergo this innovative treatment, resulting in luxurious textiles that offer exceptional resilience and a refined finish.
    </div>
    </div>
  </div>
</div>
  </div>

</section>

    </div>
  );
}