import React, { useEffect } from 'react';
import "./css/style.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, EffectFade, Pagination, Autoplay, Zoom } from "swiper";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import { useState } from "react";
import Bounce from 'react-reveal/Bounce';
import Slide from 'react-reveal/Slide';
import Roll from 'react-reveal/Roll';
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';

const options = {
  margin: 15,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 4,

    },
    1500: {
      items: 4,
    }
  },
};




export default function Home() {

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [newsletterBtn,setNewsLetterBtn] = useState("Subscribe");

  const emailValidation = () => {
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (regEx.test(email)) {
      setMessage("Email is Valid");
      setError(false);
    } else if (!regEx.test(email) && email !== "") {
      setMessage("Email is Not Valid");
      setError(true);
    } else {
      setMessage("");
    }
  };
  function sendNews(e) {
    e.preventDefault();
    setNewsLetterBtn("Subscribing...");
    emailjs.sendForm('service_vz0ol9d', 'template_4q1qvzw', e.target, '6Fqk58w9fHoZuGN7f').then(res => {
      console.log(res);
      alert("Thanks For Subscribe!!!")
    }).catch(err => console.log(err));
    setNewsLetterBtn("Subscribe");
  }
  

  const handleOnChange = (e) => {
    setEmail(e.target.value);
  };
  useEffect(() => {
    const handleClick = (event) => {
      const current = document.querySelector('.tabItem.active');
      current.classList.remove('active');
      event.currentTarget.classList.add('active');

      const rightContainers = document.querySelectorAll('.rightContainer');
      rightContainers.forEach((container) => {
        container.style.display = 'none';
      });

      const rightContainer = event.currentTarget.querySelector('.rightContainer');
      if (rightContainer) {
        rightContainer.style.display = 'flex';
      }
    };

    const tabItems = document.querySelectorAll('.tabItem');
    tabItems.forEach((item) => {
      item.addEventListener('click', handleClick);
    });

    return () => {
      tabItems.forEach((item) => {
        item.removeEventListener('click', handleClick);
      });
    };
  }, []);

  return (

    <>
      <div className="swiper">
        <div className="swiper-container">
          <Swiper
            modules={[EffectFade, Autoplay]}
            pagination={{ clickable: true }}
            effect
            autoplay={{

              disableOnInteraction: false
            }}
            speed={3000}
            slidesPerView={1}
            loop={true}
            className="swiper-wrapper"
          >


            <SwiperSlide className="swiper-slide">
              <div className="image__wrapper">
                <img src="assets/images/slide-office-new.jpg" alt="siraj-office" />
              </div>
              <div className="text-box-left text-box1">

                <h1>Our Infrastructure</h1>

                <p>
                  Step into our expansive 1668 sqft showroom, where space and inspiration seamlessly merge. Discover a world of endless fabric possibilities as you navigate through our carefully curated displays, ensuring that every square foot of our showroom leaves you captivated and inspired.
                </p>
                <br />
                <button className="cnt-2"><Link to="/contact">Contact Us</Link></button>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div className="image__wrapper">
                <img src="assets/images/slide-solid.jpg" alt="siraj-solid-fabrics" />
              </div>
              <div className="text-box text-box2">
                <h1>Fabrics</h1>
                <br />
                <p>
                  Embark on a sensory journey through our showroom, where OE and polyester fabrics reign supreme. Explore the harmonious blend of traditional craftsmanship and modern innovation as you indulge in the rich textures and exquisite designs that our meticulously curated collection has to offer. From OE's timeless elegance to polyester's versatility, our showroom invites you to experience the best of both worlds, inspiring your creative vision to soar.
                </p>
                <br />
                <button className="cnt-2"><Link to="/contact">Contact Us</Link></button>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div className="image__wrapper">
                <img src="assets/images/slide-design fab.jpg" alt="siraj-design -fabric" />
              </div>
              <div className="text-box-left text-box3">

                <h1>Design Fabrics</h1>
                <br />
                <p >
                  Unveil the world of design fabrics in our showroom, where creativity takes center stage. Immerse yourself in a symphony of patterns, colors, and textures that transcend the ordinary, inviting you to push the boundaries of design
                </p>
                <br />
                <button className="cnt-2"><Link to="/contact">Contact Us</Link></button>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div className="image__wrapper">
                <img src="assets/images/slide-yarn.jpg" alt="siraj-yarn" />
              </div>
              <div className="text-box text-box4">
                <h1>Yarns</h1>

                <p>
                  Experience the mesmerizing world of OE yarns through our captivating slider. From lustrous silks to cozy wools, each slide unveils a tantalizing glimpse of the exquisite textures and vibrant colors that await, inspiring endless possibilities for your next knitting or weaving project.
                </p>
                <br />

                <button className="cnt-2"><Link to="/contact">Contact Us</Link></button>
              </div>
            </SwiperSlide>

          </Swiper>
        </div>
      </div>
      <section className="part-2">
        <div className="counter-up">
          <div className="content">
            <div className="box">
              <div className="count-icon"><img src="assets/images/calendar.png" alt="" className="cout-img" /></div>
              <h2><span className="counter" > 2024</span></h2>
              <div className="text">year</div>
            </div>
            <div className="box">
              <div className="count-icon"><img src="/assets/images/water-saving.png" alt="" srcset="" className="cout-img" /></div>
              <h2><span className="counter" > 10000</span><span>ml</span></h2>
              <div className="text">water saving</div>
            </div>
            <div className="box">
              <div className="count-icon"><img src="assets/images/idea.png" alt="" srcset="" className="cout-img" /></div>
              <h2><span className="counter" > 500</span><span>+</span></h2>
              <div className="text">projects</div>
            </div>
            <div className="box">
              <div className="count-icon"><img src="assets/images/palette.png" alt="" srcset="" className="cout-img" /></div>
              <h2> <span className="counter" > 50</span><span>+</span></h2>
              <div className="text">colours</div>
            </div>
          </div>
        </div>
        {/* <div class="animate-container">
    <div class="siraj-nm">
      <span Style="--i:1">S</span>
      <span Style="--i:2">i</span>
      <span Style="--i:3">r</span>
      <span Style="--i:4">a</span>
      <span Style="--i:5">j</span>
      <span Style="--i:6">G</span>
      <span Style="--i:7">r</span>
      <span Style="--i:8">o</span>
      <span Style="--i:9">u</span>
      <span Style="--i:10">p</span>
      <span Style="--i:11">o</span>
      <span Style="--i:12">f</span>
      <span Style="--i:13">C</span>
      <span Style="--i:14">o</span>
      <span Style="--i:15">m</span>
      <span Style="--i:16">p</span>
      <span Style="--i:16">a</span>
      <span Style="--i:16">n</span>
      <span Style="--i:16">i</span>
      <span Style="--i:16">e</span>
      <span Style="--i:16">s</span>
   
     </div>
    </div> */}

        <br />
        <br />
        <div className="story" >
          <Slide top cascade>
            <span className="subhead_small"><img src="assets/images/sub_img.png" />About Us</span>
            <h1>
              WE HAVE A GREAT NAME IN TEXTILE
            </h1>
          </Slide>

        </div>
        <section className="story-container">

          <img src="assets/images/asset_back.png" className="asset_back_one" />

          <div className="story-row">
            <Slide bottom>
              <div className="story-image">
                <video width="480" height="320" className="image-story" onloadedmetadata="this.muted = true" alt="siraj-office" playsinline autoPlay={true} muted loop>
                  <source src="assets/images/sirajoffice.mp4" type="video/mp4" />

                  Your browser does not support this video.
                </video>
              </div>
            </Slide>
            <div className="content">
              <Slide bottom>
                <p>
                  <b>"The Siraj Group of Companies"</b> Was Formed in <b>1955</b>. In the Initial Stage, the Founder <i>"Mr.K.P.Kamaluddin”</i> Initiated This Company by Dealing With <i>"Cotton Rags"</i> and  <i>"Cotton Wipers”</i>. The Business Was Focused on Meeting the Demand of the Textile Manufacturers at a Wide Range. Later, This Business Was Directed by <i>"Mr.k Muhammad Ishak"</i>.he Widened the Business Operation Worldwide and Also With the Grace of the Almighty Became a Well-known Supplier in the Market.
                  After That, His Son <i>"Mr.Mohammad Bilal"</i>, Took This Business to the Next Stage With the Guidance of His Father by Starting the Spinning Mill in 2007  and Started Deal With Recycled Cotton and Oe Yarns...
                </p>
                <div className="read">
                <Link to='/about'>
                  <button className="read-more">
                    <span className="circle" aria-hidden="true">
                      <span className="icon arrow"></span>
                    </span>
                    <span className="button-text">Read More</span>
                  </button>
                </Link>
              </div>
              </Slide>
             
            </div>

          </div>

        </section>
        <br />
        <br />
        <br />
        <br />
        <br />
        <button onclick="topFunction()" id="topbtn" title="Go to top">Top</button>
        <br />
        <div className="story" >

          <Slide top cascade><span className="subhead_small"><img src="assets/images/sub_img.png" />Our Certification</span>
            <h1>
              COMMITMENT TO SUSTAINABILITY:<br /> PROUDLY HOLDING THE GRS CERTIFICATE
            </h1>
          </Slide>


        </div>
        <section className='certificate_section'>
        <Slide bottom>
          <div>
          <img src="assets/images/asset_certificate.png" className="asset_certificate" />
          </div>
          </Slide>
          <div className='certification_container'>
          <Slide bottom>
            <div className="certificate">

              
                <p>At our fabric showroom, we proudly hold the GRS (Global Recycled Standard) certificate, affirming our unwavering commitment to sustainability. This certification assures our customers that our fabrics and yarn meet stringent criteria for recycled content and responsible manufacturing practices, promoting a circular economy and reducing environmental impact. With the GRS certificate, we offer you not just beautiful fabrics, but also the assurance that you are making a sustainable choice for your projects.</p>
                <a href='assets/GRS_Scope_Certificate_2022-08-22 03_22_49 UTC.pdf' download><button className='certi_download'><i class="fa-solid fa-download"></i></button></a>
             
            </div>


            <div className="certi_link">

              <a href="#"><img src="assets/images/grs.png" alt="grs" /></a>

            </div>
            </Slide>
          </div>
        </section>

        <div className="story" >

          <Slide top cascade><span className="subhead_small"><img src="assets/images/sub_img.png" />Our Products</span>
            <h1>
              DISCOVER OUR EXCEPTIONAL PRODUCTS
            </h1>
          </Slide>


        </div>
        <div className="work-container">
          <img src="assets/images/asset_fabric.png" className="asset_back_facric" />
          <img src="assets/images/asset_yarn.png" className="asset_back_yarn" />
          <img src="assets/images/asset_knitting.png" className="asset_back_knitting" />
          <div className="works">
            <div className="work-card">
              <div className="work-img">
                <img src="assets/images/cutting-waste.jpg" alt="cutting waste" />
              </div>
              <div className="work-content">
                <h1>Cotton & Cutting Waste</h1>

                <p>We are highly acknowledged organization engaged in presenting a remarkable range of Cotton Fabric Cutting Waste.</p>
              </div>

            </div>
            <div className="work-card">
              <div className="work-img">
                <img src="assets/images/oe yarn mini.jpg" alt="oe yarn" />
              </div>
              <div className="work-content">
                <h1>OE Yarn</h1>

                <p>With an objective to fulfill the demands of our clients, we are engaged in manufacturing OE Yarns</p>
              </div>

            </div>
            <div className="work-card">
              <div className="work-img">
                <img src="assets/images/oe fabric.jpg" alt="recycled fabrics" />
              </div>
              <div className="work-content">
                <h1>Fabrics</h1>

                <p>Enriched by our vast industrial experience in this business, we are involved in offering an enormous quality range of Fabrics</p>
              </div>

            </div>

          </div>
        </div>

      </section>
      <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      <section className="part-3">
        <div className="parallax">
          <div className="text">
            <Bounce bottom>
              <h1>finest quality fabrics <br />&<br /> Yarn Available in variety of colours.</h1>
            </Bounce>
          </div>

        </div>
        <br />
        <br />
        <br />
      </section>
      <section className="part-4">
        <div className="story">
          <Slide top cascade><span className="subhead_small"><img src="assets/images/sub_img.png" />Shop Now</span>
            <h1>
              DISCOVER DIFFERENT COLORS OF <br /> FABRICS AND YARNS
            </h1>
          </Slide>
        </div>
        <div className='product-slider-container'>
        
        <OwlCarousel
          loop
          className="owl-theme product-slider"
          {...options}
        >

          <div className="items">
            <div className="img">
              <img src="assets/images/french terry.jpg" alt="French Terry Or LoopKnit Fabrics" />
            </div>
            <div className="content">
              <div className="title">
                french terry
              </div>

              <p>
              Indulge in supreme comfort with our French terry fabric, offering luxurious softness and unparalleled coziness for your everyday wear.
              </p>
              <div className="read">
                <Link to='/frenchterry'>      <button className="read-more">
                  <span className="circle" aria-hidden="true">
                    <span className="icon arrow"></span>
                  </span>
                  <span className="button-text">Know More</span>
                </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="items">
            <div className="img">
              <img src="assets/images/design-fab-little.jpg" alt="Design Fabrics" />
            </div>
            <div className="content">
              <div className="title">
                design fabs
              </div>

              <p>
              Elevate your style effortlessly with our OE design fabrics, crafted to seamlessly blend fashion-forward aesthetics with superior quality for a statement-making wardrobe.
              </p>
              <div className="read">
                <Link to='designfab'>
                  <button className="read-more">
                    <span className="circle" aria-hidden="true">
                      <span className="icon arrow"></span>
                    </span>
                    <span className="button-text">know more</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="items">
            <div className="img">
              <img src="assets/images/single-jersey-2.jpg" alt="Single Jersey Fabric" />
            </div>
            <div className="content">
              <div className="title">
                single jersey
              </div>

              <p>
              Experience the epitome of comfort and versatility with our single jersey fabric, designed to offer lightweight breathability and a smooth, luxurious feel for all-day wear.
              </p>
              <div className="read">
                <Link to='jerseyfabric'>
                  <button className="read-more">
                    <span className="circle" aria-hidden="true">
                      <span className="icon arrow"></span>
                    </span>
                    <span className="button-text">know more</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="items">
            <div className="img">
              <img src="assets/images/airtex-pique-2.jpg" alt="Airtex Or Pique" />
            </div>
            <div className="content">
              <div className="title">
                airtex
              </div>

              <p>
              Discover unmatched breathability and performance with our Airtex fabric, engineered to keep you cool and comfortable during even the most intense workouts or activities.
              </p>
              <div className="read">
                <Link to='/pique'>
                  <button className="read-more">
                    <span className="circle" aria-hidden="true">
                      <span className="icon arrow"></span>
                    </span>
                    <span className="button-text">know more</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>


        </OwlCarousel>
        </div>
      </section>

      <section className="spl-section" id="spl-section">
        <div className="story" >

          <Slide top cascade><span className="subhead_small"><img src="assets/images/sub_img.png" />Our Infrastructure</span>
            <h1>
              UNVEILING THE SPLENDOR: A GLIMPSE INTO<br /> OE FABRIC SHOWROOM'S EXQUISITE INFRASTRUCTURE
            </h1>
          </Slide>


        </div>
        <div className="tabContainer">
          <div className="tabInner" id="tabInner">
            <div className="tabItem active">
              <div className="tabMenuContainer">
                <h1 className="tabMenu">Showroom</h1>
              </div>
              <div className="rightContainer">
                <div className="tebItemImageContainer">
                  <img src="assets/images/infra-stock.jpg" alt="showroom" />
                </div>
                <div className="tabTextContainer">
                  <h2>OE and Polyester Fabric Paradise: Step into OE Fabric Showroom's Vibrant Collection</h2>
                  <p>Immerse yourself in a world of endless possibilities at OE Fabric Showroom, where polyester fabrics take center stage. Step into our meticulously designed space, where vibrant hues, luxurious textures, and unparalleled quality converge to inspire your creative vision.</p>

                </div>

              </div>
            </div>
            <div className="tabItem">
              <div className="tabMenuContainer">
                <h1 className="tabMenu">Display Section</h1>
              </div>
              <div className="rightContainer">
                <div className="tebItemImageContainer">
                  <img src="assets/images/infra-showroom.jpg" alt="display_section" />
                </div>
                <div className="tabTextContainer">
                  <h2>A Kaleidoscope of Textile Delights: Discover OE Showroom's Enchanting Fabric Display</h2>
                  <p>Immerse yourself in a sensory feast as you explore the Fabric Display section at OE Showroom. Witness a symphony of colors, patterns, and textures, elegantly showcased to ignite your creative vision</p>

                </div>

              </div>
            </div>
            <div className="tabItem">
              <div className="tabMenuContainer">
                <h1 className="tabMenu">Conference Hall</h1>
              </div>
              <div className="rightContainer">
                <div className="tebItemImageContainer">
                  <img src="assets/images/infra-conference.jpg" alt="conference_hall" />
                </div>
                <div className="tabTextContainer">
                  <h2>Empowering Productivity: Unleash the Potential of Our Office Conference Hall</h2>
                  <p>Step into our office conference hall, a space designed for productive collaboration and meaningful discussions. With its modern aesthetics and state-of-the-art amenities, our conference hall sets the stage for impactful meetings and successful brainstorming sessions, ensuring that every gathering leaves a lasting impression.</p>

                </div>

              </div>
            </div>
            <div className="tabItem">
              <div className="tabMenuContainer">
                <h1 className="tabMenu">Marketing And
                  Accounts Section</h1>
              </div>
              <div className="rightContainer">
                <div className="tebItemImageContainer">
                  <img src="assets/images/infra-marketing and accounts.jpg" alt="marketing" />
                </div>
                <div className="tabTextContainer">
                  <h2>Fueling Growth and Insight: Unleash the Power of Our Marketing and Accounts Section</h2>
                  <p>Experience the hub of strategic brilliance in our Marketing and Accounts Section, where innovative campaigns are born and financial mastery takes center stage. From creative marketing strategies to meticulous financial management, our dedicated team works tirelessly to drive success and elevate your business to new heights.</p>
                </div>

              </div>
            </div>
            <div className="tabItem">
              <div className="tabMenuContainer">
                <h1 className="tabMenu">Spinning Mills/Knitting Section </h1>
              </div>
              <div className="rightContainer">
                <div className="tebItemImageContainer">
                  <img src="assets/images/knitting.jpg" alt="marketing" />
                </div>
                <div className="tabTextContainer">
                  <h2>The Artistry of Fiber Transformation: Discover Our Spinning Mills/Knitting Section</h2>
                  <p>Enter the heart of textile production in our Spinning Mills/Knitting Section, where fibers are transformed into exquisite fabrics. Witness the rhythmic dance of machines as they spin, weave, and knit, crafting materials of impeccable quality, setting the foundation for extraordinary textile creations.</p>

                </div>

              </div>
            </div>
          </div>
        </div>


      </section>




      <div className='story'>
        <Slide top cascade><span className="subhead_small"><img src="assets/images/sub_img.png" />Our Blogs</span>
          <h1>
            FABRIC INSIGHTS: UNRAVELING THE WORLD OF TEXTILES - EXPLORE OUR BLOG SECTION
          </h1>
        </Slide>
      </div>
      <section className="blog">
        <img src="assets/images/asset_yarn_two.png" className='asset_back_yarn_two' />
        
          <div className="container">
          <Slide bottom>
            <Link to='/blog1'><div className="card">
              <div className="card__header">
                <img src="assets/images/blog-1.jpg" alt="siraj-blog" className="card__image" width="600" />
              </div>
              <div className="card__body">
                <span className="tag tag-blue">Apr,26</span>
                <h4>Textile recycling: A step towards sustainability</h4>
                <p>Textiles have a significant impact on the environment during their lifecycle. Large amount of water, energy, pesticides and fertilizers...</p>
              </div>
            </div>
            </Link>
            </Slide>
            <Slide bottom>
            <Link to='/blog2'>
              <div className="card">
                <div className="card__header">
                  <img src="assets/images/blog-2.jpg" alt="siraj-cotton-mills-blog" className="card__image" width="600" />
                </div>
                <div className="card__body">
                  <span className="tag tag-brown">Apr,26</span>
                  <h4>Cotton Recycling</h4>
                  <p>Cotton grows weaker during recycling and must therefore be combined with other fibres to create a fresh yarn with the appropriate...</p>
                </div>
              </div>
            </Link>
            </Slide>
            <Slide bottom>
            <Link to='/blog3'>
              <div className="card"  >
                <div className="card__header">
                  <img src="assets/images/oe yarn mini.jpg" alt="siraj-oe-yarn" className="card__image" width="600" />
                </div>
                <div className="card__body">
                  <span className="tag tag-red">Apr,26</span>
                  <h4>Recycled Knitting Yarn</h4>
                  <p> When we talk about recycled yarn, it means the mixture of earlier used garments as well as leftovers from the textile industry. The main purpose of recycled knitting...</p>
                </div>
              </div>
            </Link>
            </Slide>
          </div>
       
      </section>
      <br />
      <br />

      <section className="newsletter_container">
        <div className='newsletter_inner'>
          <h3>Newsletter Updates</h3>
          <form id="from_news_letter" onSubmit={sendNews}>
            <input type='email' className="mailbox" name="mail" placeholder="enter your email" id="email" onKeyDown={emailValidation} onChange={handleOnChange} required /><br />
            <p style={{color: error ? "#FF0000" : "#006400"  }} className="message">{message}</p>
            <button type="submit" className="btn_news_letter" >{newsletterBtn}</button>
          </form>
        </div>
      </section>
    </>

  );

}
