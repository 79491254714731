import React from "react";
import "./css/blog.css";
import {Link} from 'react-router-dom';
import Slide from 'react-reveal/Slide';
export default function Blog1()
{
 
  return(
    
    <>
    <div className="blog-body">
    <header>
    <div className="story" >

<Slide top cascade><span className="subhead_small"><img src="assets/images/sub_img.png" />Our Blog</span>
  <h1>
  The Fabric Chronicles: Exploring Trends, Tips,<br/> and Inspiration - Our Blog
  </h1>
</Slide>
</div>
 </header>
 <div className="blg-row">
 	<div className="left-column">
 	  <div className="blg-card">
 		<h2>Textile recycling: A step towards sustainability</h2>
 		<h5>Textile, Apr 26, 2022</h5>
 		<img src="assets/images/blog-1-big.jpg" alt="siraj-blog "/>
 		<p>Textiles have a significant impact on the environment during their lifecycle. Large amount of water, energy, pesticides and fertilizers have made the global textile industry one of the most polluting and waste producing industries in the world. Recycling textile has become the newest addition to the materials that are recycled and redirected from the landfill.

Recycling and reusing txtiles, fibres and waste materials is an effective method to build sustainability in the apparel industry. A report by U.S. Environmental Protection Agency states that textiles are an important source of greenhouse gas emissions. In order to reduce the greenhouse gas emissions, efforts are made to increase textile recycling. In the current scenario, recycling clothing would have an effect equivalent to removing one million cars off the road every year.

In U.K. people consume 2 million tons of clothing from which, 0.5 million tons are recycled. However, 1 million ton is still disposed off. While in Europe, one can find a textile waste of around 14million tons out of which, a quarter of 5 million tons are recycled. Therefore, it has become imperative to develop innovative methods to recycle textiles and produce beneficial items out of recycled post-consumer materials.

Textiles thrown into the landfills have become a big global problem. Natural fibers take years to decompose, whereas man-made fibers do not decompose. Woolen clothes do decompose, but release methane and carbon dioxide into the atmosphere. This contributes towards global warming. Synthetic fabrics in the landfill release nitrous oxide which is a powerful greenhouse gas. Besides, toxic substances pollute groundwater and surrounding soil.</p>
 		<p>In U.S., the manufacturers recycle 75% of the pre-consumer textile waste, whereas only 15% of post-consumer textile waste is recycled. Therefore, consumers are believed to be the biggest culprits, responsible for the textile waste. It is shown that an average American discards around 70 pounds of clothing, shoes and other textile waste every year, which means in America 13 million tons of textile waste is generated every year.

In order to curb the growth of textile waste, textile recycling is the only option. Moreover, textile recycling offers many environmental benefits. It decreases the need for landfill space, keeping in mind the greenhouse gases released from the textiles dumped. In addition, the area surrounding the landfill poses risk to the groundwater. Every time it rains, the water absorbs all the chemicals and toxic materials from whatever is dumped in the landfill such as chemicals, dyes and bleaches used on textiles.

This water gets collected at the bottom of the landfill, which can be 200 times more toxic than the sewage water. By re-using the existing fibres and textiles, it reduces the need for newly manufactured fibers. This saves water, energy, dyes and chemicals, which results into less pollution.

It is observed that if each person in UK bought one recycled woolen garment every year, it would save approximately 371 gallons of water and 480 tons of chemical dyes. Today, many fashion brands are coming forward to use recycled fibres and fabrics in their apparel collection. Recycling fashion can be done in three ways.

The first method is using textiles that are manufactured from recycled fibres or products. For example, recycled polyester made from used plastic bottles. The second method is recycling textile fabric which is also called "Upcycling". For example, using unwanted factory surplus or materials which are normally thrown away.

Thirdly, recycling clothing or garments, like using second hand clothes by repairing or re-fashioning it, to give a second life. It is found that over 70% of the world's population uses second hand clothes. The textile recycling industry in U.S. does a noteworthy job by creating 17000 jobs and removing 2.5 billion pounds of post consumer textile waste from the dump yard every year.

The textile recycling process is different for natural and synthetic fibers. For textiles made from natural fibers, the unwearable material is first sorted according to the type of the fabric and color. Through color sorting it is determined that no re-dyeing is required, which saves energy, water and reduce pollution. Depending on the final use of the yarn, the textiles are pulled into fibres, shredded or other fibres are incorporated with it.

In the next step the yarn is cleaned and mixed through a carding process. The yarn is re-spun and is ready to be used for weaving or knitting. </p><p>There are special processes of textile recycling for some fibers. Some fibres are not spun into yarns but are compressed so that they can be used for textile filling for example filling used in mattresses.

For textiles made from polyester, another process is employed wherein the garments are shredded and then granulated to make polyester chips. These chips are melted to make new fibers, which can be used in new polyester fabrics. Although, in the present situation, textile recycling is facing problems related with cost, time and technology. But, as more and more apparel manufacturers and other clothing organizations understand the importance of sustainability, more attention will be given to improve the recycling productivity.

The main obstacle in increasing the textile recycling productivity is that clothing comprises of various fibres. Sorting and reprocessing such clothing is a big challenge. Some fibers like cotton and linen can be reused but petroleum based fibres like polyester have very little chance of reuse.

Making textile sorting easy will be a notable achievement towards enhancing the efficiency of textile recycling. T4T (Textiles for Textiles) machine has been developed to improve and speed up the process of textile sorting. The machine can sort textiles based on fibre composition, color, chemical combination and other aspects. The machine can detect the specific properties of the garment and sort it quickly and efficiently. It can scan around one garment per second.

Normally the recycled textiles are used in making low priced products. However, the new technology will change this situation, as the materials that are sorted by the machine can compete with virgin materials, and are used for making high quality products. The sorting companies collect only good quality clothing but the T4T machine can even recycle textile waste into raw materials.

Different sustainability strategies are being developed for effective utilization of textile waste. Manufacturers need to have pro-active approach towards textile recycling. It is anticipated that textile recycling industry will continue to grow. Therefore, the apparel industry must aim at re-using and reprocessing clothes, fibres and scraps in the most efficient way to achieve sustainability.</p>
 	  </div>
 	 
 	</div>

 	<div className="right-column">
 		<div className="blg-card">
 			 <h3>Popular Post</h3>
 			<Link to='/blog2' ><img src="assets/images/blog-2.jpg" alt="siraj-blog"/></Link>
 		<Link to='/blog3'>	 <img src="assets/images/oe yarn mini.jpg" alt="siraj-blog"/></Link>
 		</div>
 		<div className="blg-card">
 			<h1>Follow Us</h1>
             <span id="line-2" ></span>
 			<div className="social-media blg-media">
              <div className="social-icons">
                <a href="#">
                  <i className="fa fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fa fa-instagram"></i>
                </a>
                <a href="#">
                  <i className="fa fa-youtube"></i>
                </a>
              </div>
            </div>
 		</div>
 	</div>
 </div>


</div>
    </>
  );
}