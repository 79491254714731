import React from "react";
import "./css/blog.css";
import {Link} from 'react-router-dom';
import Slide from 'react-reveal/Slide';
export default function Blog3()
{
 
  return(
    
    <>
    <div className="blog-body">
    <header>
    <div className="story" >

<Slide top cascade><span className="subhead_small"><img src="assets/images/sub_img.png" />Our Blog</span>
  <h1>
  The Fabric Chronicles: Exploring Trends, Tips,<br/> and Inspiration - Our Blog
  </h1>
</Slide>
</div>
 </header>
 <div className="blg-row">
 	<div className="left-column">
 	  <div className="blg-card">
 		<h2>Recycled Knitting Yarn </h2>
 		<h5>Knitting Yarn, Apr 26, 2022</h5>
 		<img src="assets/images/blog-yarn.jpg" alt="siraj-blog "/>
 		<p>
         <b>What is Recycled Knitting Yarn?</b>
         <br/>

         When we talk about recycled yarn, it means the mixture of earlier used garments as well as leftovers from the textile industry. The main purpose of recycled knitting yarn is to minimize unnecessary wastage. Usually, textile leftovers and old clothes are disposed of away. Recycling is a sustainable way to utilize them again. Not only in clothes but recycled knitting yarn has also become the raw material for fashionable knitted shoes. The major part of this yarn is cotton but other ingredients like wool and acrylic are also mixed for attaining different textures. Recycled knitting yarn is beneficial from the following aspects. 
</p>
<p>
<b>Advantages of Utilizing Recycled Yarn </b>
<br/>
<li>Deflates Industrial Wastage</li>
<br/>
Manufacturing industries already drain out a lot of waste material every day. It can be reduced by recycling material that is leftover during the manufacturing process. The leftover raw material also possesses the same value as processed material. Therefore, wasting it is not a wise decision. The recycling process of spendthrift material requires fewer resources that mean low cost on production.  
<li>Environment-Friendly</li>
<br/>
Every year, tons of raw and process material from factories is drained out that causes water, air, and soil pollution. With recycling, you can reduce those environmental hazards to an extent. Unrecycled material is usually dumped into landfills or water. 
<br/>
<li>Optimum Utilization of Old Garments </li>
<br/>
As we already mentioned above, recycled knitting yarn also comprises some portion of old clothes. After spinning fibers, they are blended with leftover waste material for making brand new clothes. If not recycled, old garments are completely worthless. For manufacturers, it is a fair opportunity to recycle because it is an affordable raw material. 
<br/>
<li>Cost-Effective</li>
<br/>
In the textile manufacturing industry, dye, water, and energy for processing are the three major components responsible for a higher cost. Fibers that have been processed already require less energy source, water and die. It directly reduces the overall cost of manufacturers. 
All three points are clearly illustrating that recycling knitting yarn is beneficial for manufacturers as well as the environment too. It creates a huge margin in the profit of manufacturers. However, the textile drive also has to face some challenges while recycling. There is a huge risk of contamination while blending cotton with other fibers. Also, recycling of yarn is not likely once it is utilized.
         </p>
 	  </div>
 	 
 	</div>

 	<div className="right-column">
 		<div className="blg-card">
 			 <h3>Popular Post</h3>
              <Link to='/blog2' ><img src="assets/images/blog-2.jpg" alt="siraj-blog"/></Link>
 		<Link to='/blog1'>	 <img src="assets/images/blog-1.jpg" alt="siraj-blog"/></Link>
 			
 		</div>
 		<div className="blg-card">
 			<h1>Follow Us</h1>
             <span id="line-2" ></span>
 			<div className="social-media blg-media">
              <div className="social-icons">
                <a href="#">
                  <i className="fa fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fa fa-instagram"></i>
                </a>
                <a href="#">
                  <i className="fa fa-youtube"></i>
                </a>
              </div>
            </div>
 		</div>
 	</div>
 </div>


</div>
    </>
  );
}