import React from "react";
import "./css/product.css";
import {Link} from 'react-router-dom';
export default function DesignFabric()
{
 
  return(
    
    <>
    <section className="fabric-body">
    <img src="assets/images/asset_fabric_two.png" className='asset_fabric_two'/>
    <img src="assets/images/asset_fabric.png" className='asset_fabric'/>
  <div className="pro-head"> <h1 id="Design-Fabrics-title">Design Fabrics</h1>
  <span className="pro-line"></span>
  </div>
  <div className="fab-container">
      <div className="fab-card">
          <div className="fab-picBackground">
              <div className="gradients">
                  
                  <div className="gradient" color="red"></div>
                  <div className="gradient" color="green"></div>
                  <div className="gradient" color="orange"></div>
                  <div className="gradient" color="black"></div>
                  <div className="gradient second" color="purple"></div>
              </div>
              <h1 className="sira-transprent">siraj</h1>
              <img src="assets/images/logo-white.png" alt="" className="logo-product"/>
              <a href="#" className="share"><i className="fas fa-share-alt"></i></a>
              <div className="image-cont">
              <img src="assets/images/siraj-design-fabric.jpg" alt="siraj-design-fabric" className="fab show" color="blue"/>
              <img src="img/red.png" alt="" className="fab" color="red"/>
              <img src="img/green.png" alt="" className="fab" color="green"/>
              <img src="img/orange.png" alt="" className="fab" color="orange"/>
              <img src="img/black.png" alt="" className="fab" color="black"/>
              </div>
          </div>
          <div className="info">
              <div className="fabName">
                  <div>
                      <h1 className="big">Design Fabrics</h1>
                     
                  </div>
                  
              </div>
              <div className="description">
                  <h3 className="title">Product Info</h3>
                  <p className="text">Unlock endless possibilities for your fashion line with our OE design fabric. Featuring captivating designs and patterns, this fabric serves as the perfect canvas for your creative visions. Crafted with impeccable attention to detail and superior craftsmanship, our OE design fabric empowers designers to bring their unique concepts to life. Whether you're crafting statement pieces or fashion-forward essentials, our OE design fabric ensures exceptional quality and aesthetic appeal for your brand.</p>
              </div>
              <div className="color-container">
                  <h3 className="title">Colours</h3>
                  <div className="colors">
                  <h4>Mixed colours</h4>
                  </div>
              </div>
              <div className="dia-container">
                  <h3 className="title">dia</h3>
                  <div className="dias">
                      <h4>any dia on order based</h4>
                  </div>
              </div>
              <div className="gsm-container">
                  <h3 className="title">Gsm</h3>
                  <div className="gsm">
                      <h4>160-200</h4>
                  </div>
              </div>
              <div className="cont-price">
                  <Link to='/contact' className="cont"><i className="fas fa-shopping-cart"></i>Book Your free sample</Link>
                  
              </div>
          </div>
      </div>
  </div>
</section>
    </>
    );
     
   }