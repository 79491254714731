import React from "react";
import "./css/achievements.css";
import Flip from 'react-reveal/Flip';
import Slide from 'react-reveal/Slide';
export default function Achievements()
{
 
  return(
    
    <>
    <div className="ache-container">
        <img src="assets/images/asset_yarn_two.png" className='asset_back_yarn_two'/>
        <img src="assets/images/asset_back.png" className='asset_back'/>
       
    <section id="timeline">
    <div className='story'>
      <Slide top cascade><span className="subhead_small"><img src="assets/images/sub_img.png" />Our Achievements</span>
  <h1>
  UNVEILING OUR MILESTONES:<br/> CELEBRATING REMARKABLE ACHIEVEMENTS
  </h1>
</Slide>
</div>
        <img src="assets/images/asset_fabric_two.png" className='asset_fabric_two'/>
        <img src="assets/images/acheivement_back.png" className='acheivement_back'/>
        <div className="demo-card-wrapper">
        
        <Flip left>
            <div className="demo-card demo-card--step1">
                <div className="head">
                    <div className="number-box">
                        <span>1955</span>
                    </div>
                    <h2>Siraj Group</h2>
                </div>
                <div className="body">
                    <p>The Group's initial business was “Cotton Rags” and “Wipers” has started by the founder</p>
                    <img src="assets/images/wipers.jpeg" alt="siraj-wipers"/>
                </div>
            </div>
            </Flip>
            <Flip left>
            <div className="demo-card demo-card--step2">
                <div className="head">
                    <div className="number-box">
                        <span>1980</span>
                    </div>
                    <h2>International Business</h2>
                </div>
                <div className="body">
                    <p> “Cotton Rags Wipers” - started exporting to more than 18 foreign countries</p>
                    <img src="assets/images/export.jpg" alt="siraj-exports"/>
                </div>
            </div>
            </Flip>
            <Flip left>
            <div className="demo-card demo-card--step3">
                <div className="head">
                    <div className="number-box">
                        <span>2007</span>
                    </div>
                    <h2> Spinning Mill</h2>
                </div>
                <div className="body">
                    <p>We had introduced a spinning mill  to deal in  yarns</p>
                    <img src="assets/images/oe machine.jpg" alt="siraj-oe-machine"/>
                </div>
            </div>
            </Flip>
            <Flip left>
            <div className="demo-card demo-card--step4">
                <div className="head">
                    <div className="number-box">
                        <span>2008</span>
                    </div>
                    <h2>Fabrics Sales</h2>
                </div>
                <div className="body">
                    <p>We have entered in to the Fabric Market and started exporting OE Fabrics</p>
                   
                    <img src="assets/images/siraj-jersey-fabric.jpg" alt="siraj-jersey-fabric"/>
                </div>
            </div>
            </Flip>
            <Flip left>
            <div className="demo-card demo-card--step5">
                <div className="head">
                    <div className="number-box">
                        <span>2010</span>
                    </div>
                   
                    <h2>Knitting Section</h2>
                </div>
                <div className="body">
                    <p>Our  own Knitting Section planted to meet the needs in fabric market </p>
                    <img src="assets/images/knitting.jpg" alt="siraj-knitting"/>
                </div>
            </div>
            </Flip>
        </div>
    </section>
    <img src="assets/images/asset_cottonmills.png" alt="asset_cottonmills" className="asset_cottonmills"/>

    </div>
    </>
   );
     
}