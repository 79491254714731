import React from "react";
import "./css/infrastucture.css";
import LightSpeed from 'react-reveal/LightSpeed';
import Fade from 'react-reveal/Fade';
export default function Infrastructure()
{
 
  return(
    
    <>
    <div className="infra-head">
    <h1>Infrastructure</h1>
    <span id="line-2" ></span>
    </div>
    <div className="infra-text">
    <p>In Siraj Group of Company, we use the best quality of Textile Fibre recycling and Spinning Technologies to spin our Yarns. Moreover, we use high-quality of yarns for constructing the Fabrics. We have a quality-conscious, efficient, and highly talented team to produce the products to maintain the world standard.</p>
        
        </div>  

        


    <div className="infra-container">
  <div className="infra-box">
  <LightSpeed left>
      <div className="infra-image">
          <img src="assets/images/infra-stock.jpg" alt="" className="image-infra-2"/>
      </div>
      </LightSpeed>
      <div className="content">
      <Fade right cascade>
      <h3>Showroom</h3>
      </Fade>
      </div>
  </div>

</div>

<div className="infra-container">
  <div className="infra-box-2">
  <LightSpeed right>
      <div className="infra-image">
          <img src="assets/images/infra-showroom.jpg" alt="" className="image-infra-2"/>
      </div>
      </LightSpeed>
      <div className="content">
      <Fade left cascade>
      <h3>Display Section</h3>
      </Fade>
      </div>
  </div>

</div>
<div className="infra-container">
  <div className="infra-box">
  <LightSpeed left>
      <div className="infra-image">
          <img src="assets/images/infra-conference.jpg" alt="Siraj-Infrastructure" className="image-infra-2"/>
      </div>
      </LightSpeed>
      <div className="content">
      <Fade right cascade>
      <h3>Conference Hall</h3>
      </Fade>
      </div>
  </div>

</div> 

<div className="infra-container">
  <div className="infra-box-2">
  <LightSpeed right>
      <div className="infra-image">
          <img src="assets/images/infra-marketing and accounts.jpg" alt="Siraj-Infrastructure" className="image-infra-2"/>
      </div>
      </LightSpeed>
      <div className="content">
      <Fade left cascade>
      <h3>Marketing And 
        <br/>Accounts Section </h3>
      </Fade>
      </div>
  </div>

</div>

 <div className="infra-container">
  <div className="infra-box">
  <LightSpeed left>
      <div className="infra-image">
          <img src="assets/images/infra-oe mills.jpg" alt="Siraj-Infrastructure" className="image-infra-2"/>
      </div>
</LightSpeed>
      <div className="content">
        <Fade right cascade>
      <h3>Spinning Mills</h3>
      </Fade>
      </div>
  </div>

</div>
<div className="infra-container">
  <div className="infra-box-2">
  <LightSpeed right>
      <div className="infra-image">
          <img src="assets/images/knitting.jpg" alt="Siraj-Infrastructure" className="image-infra-2"/>
      </div>
</LightSpeed>
      <div className="content">
      <Fade right cascade>
      <h3>Knitting Section </h3>
      </Fade>
      </div>
  </div>

</div>

    </>
  );
}
